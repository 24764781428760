.checkout-page {
  width: 55%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
  @media screen and (max-width: 650px){
    font-size: 14px;
    padding: 5px;
    width: 100%
  }


  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    .header-block {
      text-transform: capitalize;
      width: 23%;

      &:last-child {
        width: 8%;
      }
    }
  }

  .total {
    margin-top: 30px;
    font-size: 32px;
    align-items: center;
    @media screen and (max-width: 650px){
      font-size: 20px;
      padding: 5px
    }
  }

  .checkout {
    width: 60%;
    padding: 5%;
    display: block;
    text-align: center;
  }

  .button{
    margin-left: auto;
    margin-top: 50px;
  }

  .warning {
    text-align: center;
    color: red;
    margin-top: 40;
    font-size: 24px;
  }
}
