.input {
  width: 90%;
  padding: 5px
}

.payment-form{
  display: block;
  text-align: center;
}

.payment-button{
  font-size: 18px;
  margin: 25px;

}
