.image-slider {
  display: flex;
  align-items: center;

  .image{
    height: auto;
    width: 420px;
    @media screen and (max-width: 500px){
      height: auto;
      width: 260px;
    }
  }

  .button {
    position: relative;
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans Condensed';
    font-weight: bolder;
    border: none;
    font-size: 18px;
    color: #FFFFFF;
    margin: 1%;
    text-align: center;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
  }

  .button:after {
    content: "";
    background: #f1f1f1;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
  }

  .button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }
}
