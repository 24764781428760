.item-page {

  .image{
    height: auto;
    width: 420px;
    @media screen and (max-width: 500px){
      height: auto;
      width: 260px;
    }
  }
  
  .checkout-button{
    position: relative;

  }

  .title {

    font-size: 38px;
    margin: 0 auto 30px;
  }

  .description{
    font-size: 20px;
  }

  .price{
    font-size: 20px;
  }

}
